import {IAppliedFilter, IToolTemplateSection} from "@/app/models/auctor";
import React from "react";
import TemplateSection from "@/app/components/auctor/tool/TemplateSection";


interface IToolTemplateProps {
    sections: IToolTemplateSection[];
    appliedFilters: IAppliedFilter[];
    expressionContext?: string;
    isFloatingTemplate?: boolean;
    beforeFilterSectionsOnly?: boolean;
    afterFilterSectionsOnly?: boolean;
    toolData: any;
}

export default function ToolTemplate({
    sections,
    appliedFilters,
    expressionContext,
    toolData,
    isFloatingTemplate = false,
    beforeFilterSectionsOnly = false,
    afterFilterSectionsOnly = false,
}: IToolTemplateProps) {
    let filteredSections = sections;
    if (beforeFilterSectionsOnly) {
        filteredSections = sections.filter(section => !section.after_filter_block_on_mobile);
    } else if (afterFilterSectionsOnly) {
        filteredSections = sections.filter(section => section.after_filter_block_on_mobile);
    }

    return (
        <>
            <div className={`tool-template grid gap-6`}>
                {filteredSections.map((section) => (
                    <TemplateSection
                        section={section}
                        appliedFilters={appliedFilters}
                        expressionContext={expressionContext}
                        isFloatingTemplate={isFloatingTemplate}
                        toolData={toolData}
                        key={`section-${section.id}`}
                    />
                ))}
            </div>
        </>
    );
}

