import {IAppliedFilter, IToolTemplateBlock} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";

interface ITableBlockProps {
    block: IToolTemplateBlock
    expressionContext?: string
    appliedFilters: IAppliedFilter[]
    toolData: any
}

interface ITableContent {
    head: string[]
    rows: string[][]
}

export default function TableBlock({block, toolData, appliedFilters, expressionContext}: ITableBlockProps) {
    const {head, rows}: ITableContent = evaluateExpressionWithFiltersAndRowData(block.table_expression_value, toolData, appliedFilters, expressionContext)
    return (
        <div className={'overflow-hidden w-full px-4 relative'}>

            <div className={'overflow-x-auto w-full max-w-fit relative'}>
                <i
                    className={'h-full w-6 bg-gray-600/50 fa-solid fa-chevron-right absolute right-0 justify-center items-center flex sm:hidden'}
                />
                <table className={'border-separate border-spacing-y-2 whitespace-nowrap'}>
                    {head.length !== 0 &&
                        <thead>
                            <tr>
                                {head.map((colTitle, index) => {
                                    return (
                                        <th
                                            key={index}
                                            className={'text-center text-white p-2 font-semibold bg-blue-700 first:rounded-l last:rounded-r'}
                                        >
                                            {colTitle}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                    }

                    <tbody className={''}>
                        {rows.map((row, index) => {
                            return (
                                <tr key={index}>
                                    {row.map((content, index) => {
                                        return (
                                            <td
                                                key={index}
                                                className={'text-center text-blue-700 p-2 font-semibold first:border-l last:border-r border-y first:rounded-l last:rounded-r'}
                                            >
                                                {content}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
