import React from "react";
import dynamic from "next/dynamic";
import {IAppliedFilter, IToolTemplateBlock} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";

const GaugeComponent = dynamic(() => import('react-gauge-component'), {ssr: false});

interface IGaugeBlockProps {
    block: IToolTemplateBlock;
    expressionContext?: string;
    appliedFilters: IAppliedFilter[];
    toolData: any;
}

export default function GaugeBlock({block, appliedFilters, toolData, expressionContext}: IGaugeBlockProps) {
    let gaugeValue = evaluateExpressionWithFiltersAndRowData(block.expression_value, toolData, appliedFilters, expressionContext)
    // check that value is a valid number between min and max
    if (isNaN(gaugeValue) || gaugeValue < block.min_gauge_value || gaugeValue > block.max_gauge_value) {
        gaugeValue = block.min_gauge_value
    }

    const arcProps = {
        width: .27,
        subArcs: [
            {
                limit: gaugeValue,
                color: '#65cb65',
                showTick: false,
            },
            {color: '#f25b5b'},
        ],
        cornerRadius: 0,
        padding: 0.005,
        showTicks: false,
    }

    const pointerProps = {
        type: 'needle',
        width: 7,
        length: 0.95,
        color: '#fff',
        animate: false,
        animationDuration: 500,
        elastic: false,
    }

    const labelsProps = {
        valueLabel: {
            style: {
                position: 'relative',
                top: '50px',
                fill: '#fff',
                fontSize: '50px',
                fontWeight: '700',
                textShadow: 'none',
                transform: 'translate(0%, 40%)'
            },
            hide: true,
        },
        tickLabels: {
            ticks: [],
            defaultTickValueConfig: {
                distanceFromArc: 0,
                hide: true,
            },
            defaultTickLineConfig: {
                hide: true,
            }
        },
    }

    return (
        <div className={'flex flex-col justify-center items-center gap-2 relative min-h-[140px] min-w-[140px] bg-black/50 rounded-full'}>
            <GaugeComponent
                type="semicircle"
                minValue={block.min_gauge_value}
                maxValue={block.max_gauge_value}
                value={gaugeValue}
                arc={arcProps}
                style={{width: '140px', height: '140px', borderRadius: '50%'}}
                marginInPercent={{top: 0.04, bottom: 0.00, left: 0.14, right: 0.14}}
                // @ts-ignore
                pointer={pointerProps}
                // @ts-ignore
                labels={labelsProps}
            />
            <span className="text-4xl text-white font-semibold absolute bottom-6 whitespace-nowrap">
                {gaugeValue} {block.value_suffix}
            </span>
        </div>
    )
}

function GaugeBlockSkeleton() {
    return (
        <div className={'flex flex-col justify-center items-center gap-2 relative'}>
            <div className={'w-[140px] h-[140px] bg-gray-400 rounded-full animate-pulse'} />
        </div>
    )
}
