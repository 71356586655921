'use client'
import React, {Suspense, useEffect, useRef, useState} from "react";
import FilterList, {FiltersSkeleton} from "@/app/components/auctor/filter/FilterList";
import {applyDynamicValues, initAppliedFilters} from "@/app/utils/filters-utils";
import {IAppliedFilter, IFilterConfig} from "@/app/models/auctor";
import AppIcon from "@/app/components/atomic/AppIcon";
import ToolTemplate from "@/app/components/auctor/tool/ToolTemplate";
import TemplateSection from "@/app/components/auctor/tool/TemplateSection";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";

const LIST_LOADING_DURATION = 100;

interface ToolProps {
    template: any;
    filters: IFilterConfig[];
    pageConfig?: any;
    isPreview?: boolean;
    expressionContext?: string;
    expressionOnLoad?: string;
    toolData?: any;
}

export default function Tool({
    template,
    filters,
    pageConfig,
    expressionContext,
    expressionOnLoad,
    isPreview,
    toolData,
}: ToolProps) {
    const initialAppliedFilters = initAppliedFilters(filters, pageConfig);

    const [appliedFilters, setAppliedFilters] = useState<IAppliedFilter[]>(initialAppliedFilters);
    const [expressionOnLoadContext, setExpressionOnLoadContext] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isFilterListExpandedOnMobile, setIsFilterListExpandedOnMobile] = useState<boolean>(false);
    const [maxFilterToDisplay, setMaxFilterToDisplay] = useState<number>(filters?.length);

    const templateRef = useRef<HTMLDivElement | null>(null);

    const updateAndApplyDynamicValues = (filterToUpdate: IAppliedFilter, newValue: any) => {
        setAppliedFilters(prevAppliedFilters => {
            // First, update the applied filter value
            const updatedFilters = prevAppliedFilters.map(aFilter => {
                if (aFilter.filterId === filterToUpdate.filterId) {
                    return { ...aFilter, value: newValue };
                }
                return aFilter;
            });

            // Apply dynamic values to the updated filters
            return applyDynamicValues(updatedFilters);
        });

        setIsLoading(true);
        setTimeout(() => setIsLoading(false), LIST_LOADING_DURATION);
    };

    const hasFilters = filters?.length > 0

    useEffect(() => {
        setIsLoading(false);

        if (expressionContext) {
            setExpressionOnLoadContext(expressionContext)
        }

        if (expressionOnLoad) {
            const loadContext = evaluateExpressionWithFiltersAndRowData(expressionOnLoad, toolData, appliedFilters)

            // build a context using the expression on load result as a context variable
            // this will allow the expression to use the result of the expression on load as a variable
            const context = `const loadContext = ${JSON.stringify(loadContext)}; ${expressionContext}`
            setExpressionOnLoadContext(context)
        }
    }, [])

    return (
        <>
            <MobileTopTemplate
                templateRef={templateRef}
                template={template}
                toolData={toolData}
                appliedFilters={appliedFilters}
                expressionContext={expressionOnLoadContext}
            />

            <div className='w-full bg-blue-100 text-blue-900 py-5 px-2.5 transition-all duration-300 relative h-full'>
                <div className="container mx-auto max-w-[1024px]">
                    <div
                        className={"flex justify-center gap-8 relative flex-col lg:flex-row container place-self-center mx-auto"}
                    >
                        {hasFilters && (
                            <div
                                className={`grid items-center content-center h-fit w-full border border-blue-200 bg-white rounded-lg lg:w-1/2 lg:sticky ${isPreview ? '' : 'lg:top-[90px]'}`}
                            >
                                <Suspense key="filters-suspense" fallback={<FiltersSkeleton countDesktop={filters?.length} />}>
                                    <FilterList
                                        className="p-4 grid gap-6"
                                        filtersConfig={filters}
                                        appliedFilters={appliedFilters}
                                        updateAppliedFilter={updateAndApplyDynamicValues}
                                        displayNumberOnMobile={maxFilterToDisplay}
                                        isFilterListExpandedOnMobile={isFilterListExpandedOnMobile}
                                    />

                                    {filters?.length > maxFilterToDisplay &&
                                        <div
                                            className="flex bg-blue-600 text-white p-2 rounded-b-md cursor-pointer justify-center items-center"
                                            onClick={() => setIsFilterListExpandedOnMobile(!isFilterListExpandedOnMobile)}
                                        >
                                            <AppIcon
                                                type="regular" iconClassName={isFilterListExpandedOnMobile ? 'fa-circle-minus' : 'fa-circle-plus'}
                                                className="mr-2"
                                            />
                                            Afficher {isFilterListExpandedOnMobile ? 'moins' : 'plus'} de critères
                                        </div>
                                    }
                                </Suspense>
                            </div>
                        )}

                        <div
                            className={`hidden lg:flex flex-col h-full w-full lg:w-1/2`}
                        >
                            <ToolTemplate
                                sections={template.sections}
                                appliedFilters={appliedFilters}
                                toolData={toolData}
                                expressionContext={expressionOnLoadContext}
                            />
                        </div>

                        <div
                            className={`flex lg:hidden flex-col h-full w-full`}
                        >
                            <ToolTemplate
                                sections={template.sections.slice(1)}
                                appliedFilters={appliedFilters}
                                toolData={toolData}
                                expressionContext={expressionOnLoadContext}
                                afterFilterSectionsOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

interface MobileTopTemplateProps {
    templateRef: React.RefObject<HTMLDivElement>;
    template: any;
    appliedFilters: IAppliedFilter[];
    expressionContext?: string;
    toolData?: any;
}

function MobileTopTemplate({templateRef, template, appliedFilters, expressionContext, toolData}: MobileTopTemplateProps) {
    const scrollToTemplate = () => {
        if (templateRef.current) {
            window.scrollTo({
                top: templateRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div
                onClick={scrollToTemplate}
                className={`sticky top-2 mt-2 px-2 z-40 w-full flex lg:hidden bg-blue-100`}
            >
                <TemplateSection
                    section={template.sections[0]}
                    appliedFilters={appliedFilters}
                    expressionContext={expressionContext}
                    toolData={toolData}
                    key={`section-floating-${template.sections[0].id}`}
                    isFloatingTemplate
                />
            </div>

            <div
                ref={templateRef}
                className={`flex flex-col h-full w-full mt-2 px-2 lg:hidden bg-blue-100`}
            >
                <ToolTemplate
                    sections={template.sections.slice(1)}
                    toolData={toolData}
                    appliedFilters={appliedFilters}
                    expressionContext={expressionContext}
                    beforeFilterSectionsOnly
                />
            </div>
        </>
    )
}

