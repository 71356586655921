import {IAppliedFilter, IToolTemplateBlock, IToolTemplateSection, IToolTemplateTab} from "@/app/models/auctor";
import React, {useEffect} from "react";
import SectionBlock from "@/app/components/auctor/tool/SectionBlock";


interface ITemplateSectionProps {
    section: IToolTemplateSection;
    appliedFilters: IAppliedFilter[];
    expressionContext?: string;
    isFloatingTemplate: boolean;
    toolData: any;
}

export default function TemplateSection({section, appliedFilters, expressionContext, isFloatingTemplate, toolData}: ITemplateSectionProps) {
    const [activeTab, setActiveTab] = React.useState(0)

    useEffect(() => {
        if (section.tabs.length > 0) {
            setActiveTab(section.tabs[0].id)
        }
    }, []);

    return (
        <div
            key={`section-${section.id}`}
            className={`rounded-lg relative ${section.tabs.length > 1 ? 'top-8' : ''}`}
        >
            {section.tabs.length > 1 &&
                <SectionTabNav tabs={section.tabs} tabBaseColor={section.background_color} />
            }

            <SectionBody section={section} isFloatingTemplate={isFloatingTemplate} toolData={toolData} />
        </div>
    )

    function SectionTabNav({tabs, tabBaseColor}: { tabs: IToolTemplateTab[], tabBaseColor: string }) {
        return (
            <div
                className="flex justify-start items-center absolute -top-10 left-0 rounded-t-lg"
                style={{backgroundColor: tabBaseColor}}
            >
                {tabs.map((tab, index) => (
                    <div
                        key={`tab-nav-button-${tab.id}`}
                        className={`flex cursor-pointer text-white text-sm items-center justify-center px-4 h-10
                            ${activeTab === tab.id ? 'font-semibold' : 'bg-black/40'}
                            ${index === 0 ? 'rounded-tl-lg' : ''}
                            ${index === tabs.length - 1 ? 'rounded-tr-lg' : ''}
                        `}
                        onClick={() => setActiveTab(tab.id)}
                    >
                        {tab.tab_name}
                    </div>
                ))}
            </div>
        )
    }

    function SectionBody({section, isFloatingTemplate, toolData}: { section: IToolTemplateSection, isFloatingTemplate: boolean, toolData: any }) {
        const isTabActive = (tabId: number) => {
            if (section.tabs.length === 1) {
                return true
            }

            return activeTab === tabId
        }

        const blockPaddingToClass = (padding: string) => {
            switch (padding) {
                case 'none':
                    return 'p-0'
                case 'sm':
                    return 'p-2'
                case 'md':
                    return 'p-4'
                case 'lg':
                    return 'p-6'
                case 'xl':
                    return 'p-8'
                default:
                    return 'p-4'
            }
        }

        const hasMultipleTabs = section.tabs.length > 1

        const shouldDisplayBlock = (block: IToolTemplateBlock) => {
            if (isFloatingTemplate) {
                return block.displayed_in_floating_template
            }

            return block.displayed_in_main_template
        }

        return (
            section.tabs.map((tab) => (
                <div
                    key={`section-${section.id}-tab-${tab.id}`}
                    className={`rounded-lg grid-cols-12 gap-2 
                    ${hasMultipleTabs ? 'rounded-tl-none' : ''}
                    ${blockPaddingToClass(section.padding)} 
                    ${isTabActive(tab.id) ? 'grid' : 'hidden'}`}
                    style={{backgroundColor: section.background_color}}
                >
                    {tab.blocks.map((block) =>
                            shouldDisplayBlock(block) && (
                                <SectionBlock
                                    key={`section-${section.id}-block-${block.type}-${block.id}`}
                                    expressionContext={expressionContext}
                                    toolData={toolData}
                                    block={block}
                                    appliedFilters={appliedFilters}
                                />
                            )
                    )}
                </div>
            ))
        )
    }
}
